import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
// import * as serviceWorker from './serviceWorker';
import storeConfig from './redux/store';
import App from './App';

const store = storeConfig();
const helmetContext = {};

render(
    <StrictMode>
        <Provider store={store}>
            <HelmetProvider context={helmetContext}>
                <App />
            </HelmetProvider>
        </Provider>
    </StrictMode>,
    document.getElementById('root')
);

// serviceWorker.register();
